import axios from 'axios';
import { BASE_URL } from './Constants';
import { Category } from './model/Category';
import { Checklist } from './model/Checklist';

interface ResponseData {
    code: number;
    message: string;
    data?: any;
}

type FileStruct = {
    name: string;
    file: File;
};

class AdminAPI {
    private axios = axios.create({
        baseURL: BASE_URL,
        timeout: 5000,
        headers: {
            Accept: 'application/json',
        },
    });

    private generateChecklistFormData(checklist: Checklist) {
        const formData = new FormData();
        formData.append('checklist', JSON.stringify(checklist));

        const files: FileStruct[] = [];
        if (checklist.postFile && typeof checklist.postFile !== 'string') {
            files.push({ name: 'meta', file: checklist.postFile as File });
        }
        checklist.fields.forEach((field) => {
            if (field.postFile && typeof field.postFile !== 'string') {
                files.push({
                    name: `${field.index}`,
                    file: field.postFile as File,
                });
            }
        });
        files.forEach((strt) => {
            formData.append('files[]', strt.file, strt.name);
        });

        return formData;
    }

    public async loginRequest(
        email: string,
        password: string
    ): Promise<boolean> {
        try {
            const { data }: { data: ResponseData } = await this.axios.post(
                '/login',
                {
                    email,
                    password,
                }
            );

            return data.code === 200;
        } catch (e) {
            return Promise.reject();
        }
    }

    public async getChecklistsRequest(pageIndex: number): Promise<any> {
        try {
            const { data }: { data: ResponseData } = await this.axios.get(
                '/checklist/list/' + pageIndex
            );

            if (data.code === 200) {
                return data.data;
            }
            return false;
        } catch (e) {
            return Promise.reject(e);
        }
    }

    public async getChecklistByCategoryRequest(
        categoryText: string
    ): Promise<any> {
        try {
            const { data }: { data: ResponseData } = await this.axios.get(
                '/checklist/category/' + categoryText
            );

            if (data.code === 200) {
                return data.data;
            }
            return false;
        } catch (e) {
            return Promise.reject(e);
        }
    }

    public async createChecklistRequest(
        checklist: Checklist
    ): Promise<ResponseData> {
        try {
            const formData = this.generateChecklistFormData(checklist);

            const {
                data,
            }: {
                data: ResponseData;
            } = await this.axios.post('/checklist/create', formData, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data',
                },
            });

            return data;
        } catch (e) {
            return Promise.reject(e);
        }
    }

    public async removeChecklistRequest(_id: string): Promise<ResponseData> {
        try {
            const {
                data,
            }: {
                data: ResponseData;
            } = await this.axios.post('/checklist/remove/', { _id });

            return data;
        } catch (e) {
            return Promise.reject(e);
        }
    }

    public async updateChecklistRequest(
        _id: string,
        checklist: Checklist
    ): Promise<ResponseData> {
        const formData = this.generateChecklistFormData(checklist);
        formData.append('_id', _id);
        try {
            const {
                data,
            }: {
                data: ResponseData;
            } = await this.axios.post('/checklist/update', formData, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data',
                },
            });

            return data;
        } catch (e) {
            return Promise.reject(e);
        }
    }

    public async updateChecklistIndexesRequest(
        startIndex: number,
        endIndex: number,
        willPlus: boolean
    ): Promise<ResponseData> {
        try {
            const {
                data,
            }: {
                data: ResponseData;
            } = await this.axios.post(
                '/checklist/indexes/update',
                { startIndex, endIndex, willPlus },
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                }
            );

            return data;
        } catch (e) {
            return Promise.reject(e);
        }
    }

    public async getAllFieldsRequest(): Promise<any> {
        try {
            const { data }: { data: ResponseData } = await this.axios.get(
                '/checklist/tags/'
            );

            if (data.code === 200) {
                return data.data;
            }
            return false;
        } catch (e) {
            return Promise.reject(e);
        }
    }

    public async getAllTagsRequest(): Promise<any> {
        try {
            const { data }: { data: ResponseData } = await this.axios.get(
                '/tags/all'
            );

            if (data.code === 200) {
                return data.data;
            }
            return false;
        } catch (e) {
            return Promise.reject(e);
        }
    }

    public async getCategoriesRequest(pageIndex: number): Promise<any> {
        try {
            const { data }: { data: ResponseData } = await this.axios.get(
                '/category/list/' + pageIndex
            );

            if (data.code === 200) {
                return data.data;
            }
            return false;
        } catch (e) {
            return Promise.reject(e);
        }
    }

    public async createCategoryRequest(
        category: Category
    ): Promise<ResponseData> {
        try {
            const {
                data,
            }: {
                data: ResponseData;
            } = await this.axios.post(
                '/category/create',
                { category },
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                }
            );

            return data;
        } catch (e) {
            return Promise.reject(e);
        }
    }

    public async editCategoryRequest(category: any): Promise<ResponseData> {
        try {
            const {
                data,
            }: {
                data: ResponseData;
            } = await this.axios.post(
                '/category/edit',
                { category },
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                }
            );

            return data;
        } catch (e) {
            return Promise.reject(e);
        }
    }

    public async removeCategoryRequest(_id: string): Promise<ResponseData> {
        try {
            const {
                data,
            }: {
                data: ResponseData;
            } = await this.axios.post('/category/remove/', { _id });

            return data;
        } catch (e) {
            return Promise.reject(e);
        }
    }

    public async getAllCategoreisRequest(): Promise<any> {
        try {
            const { data }: { data: ResponseData } = await this.axios.get(
                '/category/all/'
            );

            return data;
        } catch (e) {
            return Promise.reject(e);
        }
    }
}

export const adminAPI = new AdminAPI();
