import React, { FC, useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { RouteComponentProps } from 'react-router';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import CardMedia from '@material-ui/core/CardMedia';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ImportExportIcon from '@material-ui/icons/ImportExport';

import { ChecklistField } from '../../data/model/Checklist';
import IndexLabel from '../IndexLabel';
import { TitleLabel } from '../Typography';
import { AnswerType, OptionAnswer } from '../../data/model/Answer/Answer';
import { generateImageUrl } from '../../utils/helper';
import TagsInput from '../TagsInput';
import TagSearchModal from './SearchModal';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexWrap: 'wrap',
            width: '100%',
            margin: '0 auto',
            '& > *': {
                padding: theme.spacing(5),
                paddingTop: 0,
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(2),
            },
        },
        paperRoot: {
            borderLeft: `3px solid ${theme.palette.primary.main}`,
        },
        button: {
            width: '100%',
        },
        formControl: {
            width: '100%',
            marginBottom: theme.spacing(2),
            '& .MuiInputBase-root': {
                height: 48,
            },
        },
        description: {
            whiteSpace: 'pre-wrap',
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(3),
            lineBreak: 'auto',
        },
        link: {
            marginTop: theme.spacing(1),
            lineBreak: 'anywhere',
        },
        toolbar: {
            textAlign: 'right',
        },
        yesnoButton: {
            color: 'white',
            width: '100%',
        },
        checkbox: {
            marginBottom: 10,
        },
        media: {
            height: 0,
            paddingTop: '56.25%', // 16:9
            backgroundSize: 'contain',
        },
        menuIcon: {
            minWidth: 30,
        },
    })
);

interface Props extends RouteComponentProps {
    data: ChecklistField;
    fieldIndex: number;
    editChecklistField: (index: number, editable: boolean) => void;
    removeChecklistField: (fieldIndex: number) => void;
    addChecklistField: (fieldIndex: number, field: ChecklistField) => void;
    changeFieldPosition: (orgIndex: number, newIndex: number) => void;
}

const ChecklistInputViews: FC<Props> = ({
    data,
    fieldIndex,
    editChecklistField,
    removeChecklistField,
    addChecklistField,
    changeFieldPosition,
}) => {
    const classes = useStyles();
    const photoUrl = generateImageUrl(data.postFile);

    const [showTagSearchModal, setShowTagSearchmodal] = useState(false);
    const [willAddAbove, setWillAddAbove] = useState(false);
    const [addNewAnchorEl, setAddNewAnchorEl] = useState<null | HTMLElement>(
        null
    );
    const [
        moveFieldAnchorEl,
        setMoveFieldAnchorEl,
    ] = useState<null | HTMLElement>(null);

    const popupActionMenu = (
        event: React.MouseEvent<HTMLButtonElement>,
        isAddMenu: boolean
    ) => {
        if (isAddMenu) {
            setAddNewAnchorEl(event.currentTarget);
        } else {
            setMoveFieldAnchorEl(event.currentTarget);
        }
    };

    const popupMenuClose = () => {
        setAddNewAnchorEl(null);
        setMoveFieldAnchorEl(null);
    };

    const showModal = (isUp: boolean) => {
        setWillAddAbove(isUp);
        setShowTagSearchmodal(true);
        popupMenuClose();
    };

    const moveField = (isUp: boolean) => {
        popupMenuClose();
        changeFieldPosition(fieldIndex, fieldIndex + (isUp ? -1 : 1));
    };

    return (
        <div className="container">
            <div className={classes.root}>
                <Paper elevation={3} className={classes.paperRoot}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} className={classes.toolbar}>
                            <IconButton
                                aria-label="edit"
                                onClick={() =>
                                    editChecklistField(fieldIndex, true)
                                }
                            >
                                <EditIcon />
                            </IconButton>
                            <IconButton
                                aria-label="remove"
                                onClick={() => removeChecklistField(fieldIndex)}
                            >
                                <DeleteForeverIcon />
                            </IconButton>
                            <IconButton
                                aria-controls="add-field-menu"
                                aria-haspopup="true"
                                onClick={(evt) => popupActionMenu(evt, true)}
                            >
                                <AddCircleOutlineIcon />
                            </IconButton>
                            <Menu
                                id="add-field-menu"
                                anchorEl={addNewAnchorEl}
                                keepMounted
                                open={Boolean(addNewAnchorEl)}
                                onClose={popupMenuClose}
                            >
                                <MenuItem onClick={() => showModal(true)}>
                                    <ListItemIcon className={classes.menuIcon}>
                                        <ArrowUpwardIcon fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="inherit">
                                        Add new step above this
                                    </Typography>
                                </MenuItem>
                                <MenuItem onClick={() => showModal(false)}>
                                    <ListItemIcon className={classes.menuIcon}>
                                        <ArrowDownwardIcon fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="inherit">
                                        Add new step below this
                                    </Typography>
                                </MenuItem>
                            </Menu>

                            <IconButton
                                aria-controls="move-field-menu"
                                aria-haspopup="true"
                                onClick={(evt) => popupActionMenu(evt, false)}
                            >
                                <ImportExportIcon />
                            </IconButton>
                            <Menu
                                id="move-field-menu"
                                anchorEl={moveFieldAnchorEl}
                                keepMounted
                                open={Boolean(moveFieldAnchorEl)}
                                onClose={popupMenuClose}
                            >
                                <MenuItem onClick={() => moveField(true)}>
                                    <ListItemIcon className={classes.menuIcon}>
                                        <ArrowUpwardIcon fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="inherit">
                                        Move this step upward
                                    </Typography>
                                </MenuItem>
                                <MenuItem onClick={() => moveField(false)}>
                                    <ListItemIcon className={classes.menuIcon}>
                                        <ArrowDownwardIcon fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="inherit">
                                        Move this step downward
                                    </Typography>
                                </MenuItem>
                            </Menu>
                        </Grid>
                        <IndexLabel text={fieldIndex + 1} />
                        <TitleLabel text={data.title} />
                        {data.answer.type === AnswerType.CHECKBOX && (
                            <Checkbox
                                checked={false}
                                color="primary"
                                className={classes.checkbox}
                            />
                        )}
                    </Grid>
                    <Link
                        variant={'h4'}
                        color={'secondary'}
                        underline="always"
                        className={classes.link}
                    >
                        {data.postUrl}
                    </Link>
                    {photoUrl && (
                        <CardMedia
                            className={classes.media}
                            image={photoUrl}
                            title="Field Photo"
                        />
                    )}
                    <Typography className={classes.description}>
                        {data.description}
                    </Typography>
                    {data.answer.type === AnswerType.OPTION && (
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Answers</FormLabel>
                            <RadioGroup aria-label="gender" name="gender1">
                                {data.answer.descriptions.map(
                                    (answer: OptionAnswer, index: number) => (
                                        <FormControlLabel
                                            control={<Radio checked={false} />}
                                            label={answer.answer}
                                            key={index}
                                        />
                                    )
                                )}
                            </RadioGroup>
                        </FormControl>
                    )}
                    {data.answer.type === AnswerType.YESNO && (
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Button
                                    className={classes.yesnoButton}
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    startIcon={<CheckIcon />}
                                    onClick={() => {}}
                                >
                                    YES
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    className={classes.yesnoButton}
                                    variant="contained"
                                    color="secondary"
                                    size="large"
                                    startIcon={<ClearIcon />}
                                    onClick={() => {}}
                                >
                                    No
                                </Button>
                            </Grid>
                        </Grid>
                    )}

                    <TagsInput tags={data.tags} viewOnly />
                    <TagSearchModal
                        open={showTagSearchModal}
                        setOpen={setShowTagSearchmodal}
                        onFinish={(field: ChecklistField) => {
                            addChecklistField(
                                fieldIndex + +!willAddAbove,
                                field
                            );
                        }}
                    />
                </Paper>
            </div>
        </div>
    );
};

export default ChecklistInputViews;
