import { Answer } from './Answer/Answer';

export class Checklist {
    public index: number = 0;
    public title: string = '';
    public thumbnail: string = '';
    public postUrl: string = '';
    public postFile: File | string | undefined = undefined;
    public category: string = 'All';
    public createdAt: string = '';
    public updatedAt: string = '';
    public fields: ChecklistField[] = [];
}

export class ChecklistField {
    public index: number = 0;
    public title: string = '';
    public postUrl: string = '';
    public postFile: File | string | undefined = undefined;
    public description: string = '';

    public answer: Answer = new Answer();
    public tags: string[] = [];
}
