import React from 'react';
import { Switch, withRouter, Route, Redirect } from 'react-router';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { ToastProvider } from 'react-toast-notifications';

import { Layout } from './components/Layout';
import { routes } from './configs/routes';
import Login from './containers/Login';
import Checklists from './containers/Checklists';
import Categories from './containers/Categories';
import { theme } from './configs/theme';
import ChecklistsCreate from './containers/Checklists/create';
import ChecklistsView from './containers/Checklists/view';
import CategoryEdit from './containers/Categories/edit';
import CategoryCreate from './containers/Categories/create';

function App() {
    return (
        <ThemeProvider theme={theme}>
            <ToastProvider autoDismiss={true} autoDismissTimeout={3000}>
                <Layout>
                    <Switch>
                        <Route component={Login} path={routes.root} exact />

                        <Route
                            component={(props: any) => (
                                <Checklists {...props} />
                            )}
                            path={routes.checklists.root}
                            exact
                        />
                        <Route
                            component={(props: any) => (
                                <ChecklistsCreate {...props} />
                            )}
                            path={routes.checklists.create}
                            exact
                        />
                        <Route
                            component={(props: any) => (
                                <ChecklistsView {...props} />
                            )}
                            path={routes.checklists.view}
                            exact
                        />

                        <Route
                            component={Categories}
                            path={routes.categories.root}
                            exact
                        />
                        <Route
                            component={(props: any) => (
                                <CategoryCreate {...props} />
                            )}
                            path={routes.categories.create}
                            exact
                        />
                        <Route
                            component={(props: any) => (
                                <CategoryEdit {...props} />
                            )}
                            path={routes.categories.edit}
                            exact
                        />

                        <Redirect to={routes.root} />
                    </Switch>
                </Layout>
            </ToastProvider>
        </ThemeProvider>
    );
}

export default withRouter(App);
