import { generateRoute } from '../utils/helper';

export const routes = {
    root: '/',
    checklists: {
        root: '/checklists/:id',
        create: '/create-checklist',
        edit: '/edit-checklist/',
        view: '/view-checklist/',
    },
    categories: {
        root: '/categories/:id',
        create: '/create-category',
        edit: '/edit-category',
    },
    search: '/search',
};

export const makeRoute = {
    checklistIndex: (id: number) => generateRoute(routes.checklists.root, id),
    checklistEdit: (id: number) => generateRoute(routes.checklists.edit, id),
    checklistView: (id: number) => generateRoute(routes.checklists.view, id),
    categoryIndex: (id: number) => generateRoute(routes.categories.root, id),
};
