import React, { FC } from 'react';
import AppsIcon from '@material-ui/icons/Apps';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import Paper from '@material-ui/core/Paper';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import { VIEW_METHOD_KEY } from '../../data/Constants';

const useStyles = makeStyles((theme) => ({
    toggleButtonRoot: {
        '&.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
        },
        '&.Mui-selected:hover': {
            backgroundColor: theme.palette.primary.light,
            color: 'white',
        },
    },
    paper: {
        display: 'flex',
        border: `1px solid ${theme.palette.divider}`,
        flexWrap: 'wrap',
        borderRadius: theme.shape.borderRadius,
        width: 90,
    },
}));

type Props = {
    viewMethod: string;
    setViewMethod: (newMethod: string) => void;
};

const DisplayCategory: FC<Props> = ({ viewMethod, setViewMethod }) => {
    const classes = useStyles();

    const handleViewMethod = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string | null
    ) => {
        if (newAlignment !== null) {
            setViewMethod(newAlignment);
            localStorage.setItem(VIEW_METHOD_KEY, newAlignment);
        }
    };

    return (
        <Paper elevation={1} className={classes.paper}>
            <StyledToggleButtonGroup
                size="small"
                value={viewMethod}
                exclusive
                onChange={handleViewMethod}
                aria-label="view method"
            >
                <ToggleButton
                    value="grid"
                    aria-label="grid"
                    classes={{
                        root: classes.toggleButtonRoot,
                    }}
                >
                    <AppsIcon />
                </ToggleButton>
                <ToggleButton
                    value="detailed"
                    aria-label="detailed"
                    classes={{
                        root: classes.toggleButtonRoot,
                    }}
                >
                    <FormatListBulletedIcon />
                </ToggleButton>
            </StyledToggleButtonGroup>
        </Paper>
    );
};

const StyledToggleButtonGroup = withStyles((theme) => ({
    grouped: {
        margin: theme.spacing(0.5),
        border: 'none',
        '&:not(:first-child)': {
            borderRadius: theme.shape.borderRadius,
        },
        '&:first-child': {
            borderRadius: theme.shape.borderRadius,
        },
    },
}))(ToggleButtonGroup);

export default DisplayCategory;
