import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
    palette: {
        primary: {
            main: 'rgba(09, 68, 115, 1)',
            light: 'rgba(09, 68, 115, 0.8)',
        },
        secondary: {
            main: 'rgba(05, 180, 204, 1)',
            light: 'rgba(05, 180, 204, 0.8)',
        },
    },
    typography: {
        h1: {
            fontSize: 30,
        },
        h2: {
            fontSize: 22,
        },
        h3: {
            fontSize: 20,
        },
        h4: {
            fontSize: 18,
        },
        h5: {
            fontSize: 15,
        },
        h6: {
            fontSize: 12,
        },
    },
    shape: {
        borderRadius: 10,
    },
});
