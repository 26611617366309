import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const CssTextField = withStyles({
    root: {
        width: '100%',
        marginBottom: 20,
        '& label.Mui-focused': {
            color: 'rgba(05, 180, 204, 1)',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'rgba(05, 180, 204, 1)',
        },
    },
})(TextField);

export default CssTextField;
