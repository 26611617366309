import React, { FC, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Alert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useToasts } from 'react-toast-notifications';

import CssTextField from '../../components/TextField';
import { Category } from '../../data/model/Category';
import { adminAPI } from '../../data/AdminAPI';

interface Props extends RouteComponentProps {}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexWrap: 'wrap',
            width: '100%',
            '& > *': {
                padding: theme.spacing(5),
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(2),
            },
        },
        button: {
            width: '100%',
            color: 'white',
        },
        item: {
            margin: '0 auto',
        },
        paper: {
            padding: theme.spacing(3),
        },
    })
);

const CategoryCreate: FC<Props> = () => {
    const classes = useStyles();
    const history = useHistory();
    const { addToast } = useToasts();

    const [showError, setShowError] = useState(false);
    const [title, setTitle] = useState<string>('');

    const updateCategory = async () => {
        if (!title.trim()) {
            setShowError(true);
            return;
        }
        setShowError(false);

        const category = new Category();
        category.title = title;

        const response = await adminAPI.createCategoryRequest(category);
        if (response.code !== 200) {
            addToast(response.message, { appearance: 'error' });
        } else {
            setTitle('');
            addToast(response.message, {
                appearance: 'success',
            });
        }
    };

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12} sm={8} md={6} xl={4} className={classes.item}>
                <Paper elevation={3} className={classes.paper}>
                    {showError && (
                        <Alert severity="error">Title can not be empty!</Alert>
                    )}

                    <CssTextField
                        label="Title"
                        value={title}
                        onChange={(evt) => setTitle(evt.target.value)}
                    />

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <Button
                                className={classes.button}
                                variant="contained"
                                color="primary"
                                onClick={updateCategory}
                            >
                                Confirm
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button
                                className={classes.button}
                                variant="contained"
                                color="secondary"
                                onClick={() => history.go(-1)}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default CategoryCreate;
