/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import { adminAPI } from '../../data/AdminAPI';
import { Category } from '../../data/model/Category';

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: '100%',
        '& .MuiInputBase-root': {
            height: 48,
        },
    },
    select: {
        '& .Mui-disabled': {
            color: 'black',
        },
    },
}));

interface Props {
    category: string;
    updateCategory: (val: string) => void;
    disabled?: boolean;
}

export default function CategorySelect(props: Props) {
    const classes = useStyles();
    const mountedRef = useRef(true);
    const [categories, setCategories] = useState<string[]>([]);

    useEffect(() => {
        fetchCategories();
        return () => {
            mountedRef.current = false;
        };
    }, []);

    const handleCategories = (event: any) => {
        props.updateCategory(event.target.value);
    };

    const fetchCategories = useCallback(() => {
        adminAPI.getAllCategoreisRequest().then((data) => {
            if (!mountedRef.current) return;
            if (data.code === 200) {
                const realCategories = data.data.map(
                    (category: Category) => category.title
                );
                setCategories(realCategories);
            }
        });
    }, [mountedRef, setCategories]);

    return (
        <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-simple-select-outlined-label">
                Categories
            </InputLabel>
            <Select
                className={classes.select}
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={
                    categories.includes(props.category) ? props.category : 'All'
                }
                onChange={handleCategories}
                label="Categories"
                disabled={props.disabled}
            >
                <MenuItem value={'All'}>All</MenuItem>
                {categories.map((categoryTitle, index) => (
                    <MenuItem value={categoryTitle} key={index}>
                        {categoryTitle}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
