import slugifyLib from 'slugify';
import moment from 'moment';
import { SESSION_KEY, THUMBNAIL_BASE_URL } from '../data/Constants';
import { routes } from '../configs/routes';

export const formatDate = (date: string) => moment(date).format('MMMM D, YYYY');

export const transformTextRendered = (text: string) =>
    text
        .replace(
            /<img (.*)(\/?)>(.*)<noscript>(.*)"?(.*)"?(.*)<\/noscript>/g,
            '$4'
        )
        .replace(/data-src/g, 'src');

export const html2txt = (htmlText: string) => {
    const span = document.createElement('span');
    span.innerHTML = htmlText;
    return span.innerText;
};

export const slugify = (text: string) =>
    slugifyLib(text, {
        replacement: '-',
        lower: true,
        strict: true,
    });

export const generateRoute = (path: string, id: number) =>
    path.replaceAll(':id', id.toString());

const firstImgSrcRegEx = /<img.+src=(?:"|')(.+?)(?:"|')(?:.+?)>/i;

export const extractFirstImgFromContent = (content: string): string => {
    const firstImgSrc = firstImgSrcRegEx.exec(content);
    if (firstImgSrc) return firstImgSrc[1];
    return 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
};

export const generateImageUrl = (data: File | string | undefined) => {
    let photoUrl = undefined;
    if (!data) {
        photoUrl = '';
    } else if (typeof data === 'string') {
        photoUrl = THUMBNAIL_BASE_URL + data;
    } else {
        photoUrl = URL.createObjectURL(data);
    }

    return photoUrl;
};

export const validateSession = (history: any) => {
    const isRemembered = localStorage.getItem(SESSION_KEY) === 'true';
    if (!isRemembered) {
        history.push(routes.root);
    }
};

export function sleep(delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}
