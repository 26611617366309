export enum AnswerType {
    CHECKBOX = 0,
    OPTION,
    YESNO,
}

export class OptionAnswer {
    public checked: boolean = false;
    public answer: string = '';
}
export class Answer {
    public type: AnswerType = AnswerType.CHECKBOX;
    public descriptions: OptionAnswer[] = [];
}
