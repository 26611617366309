import React, { FC, useRef, useState, useCallback, useEffect } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import HomeIcon from '@material-ui/icons/Home';
import AppsIcon from '@material-ui/icons/Apps';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';

import { makeRoute, routes } from '../../configs/routes';
import './style.scss';
import { LOGIN_KEY, SESSION_KEY } from '../../data/Constants';

const HeaderHeight = 60;
const useStyles = makeStyles((theme) => ({
    tabs: {
        flexGrow: 1,
        maxWidth: 320,
        margin: '0 auto',
        color: 'white',
        height: HeaderHeight,
    },
    tab: {
        padding: 0,
    },
    labelIcon: {
        minHeight: HeaderHeight,
        paddingTop: 0,
    },
    tabWrapper: {
        display: 'contents',
        fontSize: 15,
    },
    svgIcon: {
        marginBottom: '0 !important',
    },

    headerRight: {
        display: 'flex',
        '& > *': {
            margin: 10,
        },
        position: 'absolute',
        right: 0,
        top: 0,
        height: HeaderHeight,
    },
    avatar: {
        backgroundColor: '#154473',
    },

    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.common.white,
        width: 'auto',
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1,
        color: 'white',
    },
    inputRoot: {},
    inputInput: {
        padding: theme.spacing(1.5, 1, 1.5, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create(['width', 'background-color']),
        cursor: 'pointer',
        backgroundColor: '#154473',
        color: 'white',
        width: 0,
        '&:focus': {
            width: 'calc(100vw - 88px)',
            [theme.breakpoints.up('sm')]: {
                width: 360,
            },
        },
    },
}));

const HeaderUnconnected: FC<RouteComponentProps> = ({ history }) => {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const inputRef = useRef(null);
    const [search, setSearch] = useState<string>('');
    const [showHeader, setShowHeader] = useState(false);

    useEffect(() => {
        if (history.location.pathname?.includes('login')) {
            setShowHeader(false);
        } else {
            setShowHeader(true);
        }

        setValue(
            +history.location.pathname.includes('categories') ||
                +history.location.pathname.includes('category')
        );
    }, [history.location.pathname]);

    const handleSearchChange = useCallback(
        ({ target: { value } }) => setSearch(value),
        [setSearch]
    );
    const handleSearchKeyPress = useCallback(
        ({ key }: { key: string }) => {
            if (key !== 'Enter') return;
            setSearch('');
            if (inputRef && inputRef.current) {
                // @ts-ignore
                inputRef.current.blur(); // eslint-disable-line
            }
            history.push(routes.search + `?s=${search}`);
        },
        [history, search]
    );

    const handleChange = (event: any, newValue: number) => {
        setValue(newValue);
    };

    const logoutApp = () => {
        localStorage.setItem(LOGIN_KEY, 'false');
        localStorage.setItem(SESSION_KEY, 'false');
        history.push(routes.root);
    };

    return showHeader ? (
        <div className="header">
            <div className="logo-container">
                <Link to={makeRoute.checklistIndex(1)}>
                    <img
                        src={`/logo.png`}
                        alt="HVAC School Logo"
                        className="logo"
                    />
                </Link>
            </div>
            <Tabs
                value={value}
                onChange={handleChange}
                className={classes.tabs}
                variant="fullWidth"
                indicatorColor="secondary"
                textColor="inherit"
                aria-label="icon label tabs example"
            >
                <Tab
                    icon={<HomeIcon classes={{ root: classes.svgIcon }} />}
                    classes={{
                        root: classes.tab,
                        labelIcon: classes.labelIcon,
                        wrapper: classes.tabWrapper,
                    }}
                    label="Checklists"
                    wrapped={true}
                    onClick={() => history.push(makeRoute.checklistIndex(1))}
                />
                <Tab
                    icon={<AppsIcon classes={{ root: classes.svgIcon }} />}
                    classes={{
                        root: classes.tab,
                        labelIcon: classes.labelIcon,
                        wrapper: classes.tabWrapper,
                    }}
                    label="Categories"
                    onClick={() => history.push(makeRoute.categoryIndex(1))}
                />
            </Tabs>
            <div className={classes.headerRight}>
                <div className={classes.search}>
                    <div className={classes.searchIcon}>
                        <SearchIcon />
                    </div>
                    <InputBase
                        placeholder="Search…"
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        inputProps={{
                            'aria-label': 'search',
                            ref: inputRef,
                        }}
                        value={search}
                        onChange={handleSearchChange}
                        onKeyPress={handleSearchKeyPress}
                        type="search"
                    />
                </div>
                <div onClick={logoutApp}>
                    <Avatar alt="Bryan Orr" className={classes.avatar}>
                        <ExitToAppIcon />
                    </Avatar>
                </div>
            </div>
        </div>
    ) : (
        <div />
    );
};

export const Header = withRouter(HeaderUnconnected);
