import React, { FC, useState, useEffect, useCallback, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { RouteComponentProps } from 'react-router';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import ChecklistCard from '../../components/Card';
import PaginationControlled from '../../components/Pagination';
import { makeRoute, routes } from '../../configs/routes';
import { Checklist } from '../../data/model/Checklist';
import { adminAPI } from '../../data/AdminAPI';
import { validateSession } from '../../utils/helper';
import DisplayCategory from '../../components/DisplayCategory';
import CategorySelect from '../../components/CategorySelect';
import { VIEW_METHOD_KEY } from '../../data/Constants';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: `${theme.spacing(2)}px ${theme.spacing(5)}px`,
    },
    divider: {
        margin: theme.spacing(1, 0.5),
    },
    header: {
        display: 'inline-box',
        width: '100%',
        position: 'relative',
    },
    createButton: {
        position: 'absolute',
        right: 0,
        backgroundColor: theme.palette.secondary.main,
        fontWeight: 600,
        height: 46,
        clear: 'right',
        '&:hover': {
            backgroundColor: theme.palette.secondary.light,
        },
    },
    body: {
        flexGrow: 1,
        marginTop: theme.spacing(2),
    },
    select: {
        minWidth: 150,
        marginLeft: theme.spacing(1),
    },
}));

interface Props extends RouteComponentProps {}

const Checklists: FC<Props> = ({ history, match }) => {
    validateSession(history);

    const classes = useStyles();
    const mountedRef = useRef(true);

    const [viewMethod, setViewMethod] = useState<string>(
        localStorage.getItem(VIEW_METHOD_KEY) || 'grid'
    );
    const [checklists, setChecklists] = useState<Checklist[]>([]);
    const [pageIndex, setPageIndex] = useState(1);
    const [pageCount, setPageCount] = useState(1);
    const [categoryValue, setCategoryValue] = useState('All');
    const [willUpdate, forceUpdate] = useState(0);

    useEffect(() => {
        //@ts-ignore
        const _pageIndex = match.params.id || 1;
        setPageIndex(+_pageIndex);
        return () => {
            mountedRef.current = false;
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (viewMethod === 'detailed') {
            fetchChecklistsByCategory(categoryValue);
        } else {
            if (categoryValue !== 'All') {
                fetchChecklistsByCategory(categoryValue);
            } else {
                fetchChecklists(pageIndex);
            }
        }
    }, [viewMethod, willUpdate, pageIndex]); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchChecklists = useCallback(
        (_pageIndex) => {
            adminAPI.getChecklistsRequest(_pageIndex).then((data) => {
                if (!mountedRef.current) return;
                if (data.hasOwnProperty('docs')) {
                    setChecklists(data.docs);
                    setPageCount(data.totalPages);
                }
            });
        },
        [mountedRef]
    );

    const fetchChecklistsByCategory = async (categoryText: string) => {
        setCategoryValue(categoryText);
        const data = await adminAPI.getChecklistByCategoryRequest(categoryText);
        setChecklists(data);
        setPageCount(1);
    };

    const onSortEnd = async ({
        oldIndex,
        newIndex,
    }: {
        oldIndex: number;
        newIndex: number;
    }) => {
        const startIndex = oldIndex > newIndex ? newIndex : oldIndex;
        const endIndex = oldIndex > newIndex ? oldIndex : newIndex;

        setChecklists(arrayMove(checklists, oldIndex, newIndex));
        await adminAPI.updateChecklistIndexesRequest(
            checklists[startIndex].index,
            checklists[endIndex].index,
            oldIndex > newIndex
        );
        forceUpdate((prev) => prev + 1);
    };

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <DisplayCategory
                    viewMethod={viewMethod}
                    setViewMethod={setViewMethod}
                />
                <div className={classes.select}>
                    <CategorySelect
                        category={categoryValue}
                        updateCategory={(val) => fetchChecklistsByCategory(val)}
                    />
                </div>

                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.createButton}
                    startIcon={<AddIcon />}
                    onClick={() => history.push(routes.checklists.create)}
                >
                    Create
                </Button>
            </div>

            <div className={classes.body}>
                <SortableList
                    distance={2}
                    axis="xy"
                    items={checklists}
                    viewMethod={viewMethod}
                    onSortEnd={onSortEnd}
                    forceUpdate={() => forceUpdate((prev) => prev + 1)}
                />
            </div>

            <PaginationControlled
                pageCount={pageCount}
                pageIndex={pageIndex}
                updatePageIndex={(index: number) => {
                    history.push(makeRoute.checklistIndex(index));
                }}
            />
        </div>
    );
};

export default Checklists;

type SortableProps = {
    item?: Checklist;
    items?: Checklist[];
    forceUpdate: () => void;
    viewMethod: string;
};
const SortableItem = SortableElement<SortableProps>((props: SortableProps) =>
    props.viewMethod === 'grid' ? (
        <Grid item xs={12} sm={6} md={4} xl={3}>
            <ChecklistCard
                viewMethod={props.viewMethod}
                data={props.item!}
                refresh={() => props.forceUpdate()}
            />
        </Grid>
    ) : (
        <Grid item xs={12}>
            <ChecklistCard
                viewMethod={props.viewMethod}
                data={props.item!}
                refresh={() => props.forceUpdate()}
            />
        </Grid>
    )
);

const SortableList = SortableContainer((props: SortableProps) => {
    return (
        <Grid container spacing={3}>
            {props.items!.map((item, index) => (
                <SortableItem
                    key={`item-${index}`}
                    index={index}
                    item={item}
                    forceUpdate={props.forceUpdate}
                    viewMethod={props.viewMethod}
                />
            ))}
        </Grid>
    );
});
