import React, { FC, useState, useEffect, useCallback, useRef } from 'react';
import { RouteComponentProps } from 'react-router';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';

import { validateSession } from '../../utils/helper';
import { Category } from '../../data/model/Category';
import CategoryCard from '../../components/CategoryCard';
import DisplayCategory from '../../components/DisplayCategory';
import PaginationControlled from '../../components/Pagination';
import { makeRoute, routes } from '../../configs/routes';
import { adminAPI } from '../../data/AdminAPI';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: `${theme.spacing(2)}px ${theme.spacing(5)}px`,
    },
    header: {
        display: 'inline-box',
        width: '100%',
        position: 'relative',
        marginBottom: theme.spacing(2),
    },
    createButton: {
        position: 'absolute',
        right: 0,
        backgroundColor: theme.palette.secondary.main,
        fontWeight: 600,
        height: 46,
        clear: 'right',
        '&:hover': {
            backgroundColor: theme.palette.secondary.light,
        },
    },
}));

const Categories: FC<Props> = ({ history, match }) => {
    validateSession(history);
    const classes = useStyles();
    const mountedRef = useRef(true);

    const [categories, setCategories] = useState<Category[]>([]);
    const [viewMethod, setViewMethod] = useState('grid');
    const [pageIndex, setPageIndex] = useState(1);
    const [pageCount, setPageCount] = useState(1);
    const [willUpdate, forceUpdate] = useState(0);

    useEffect(() => {
        //@ts-ignore
        const _pageIndex = match.params.id || 1;
        setPageIndex(+_pageIndex);

        return () => {
            mountedRef.current = false;
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchCategories(pageIndex);
    }, [willUpdate, pageIndex]); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchCategories = useCallback(
        (_pageIndex) => {
            adminAPI.getCategoriesRequest(_pageIndex).then((data) => {
                if (!mountedRef.current) return;
                if (data.hasOwnProperty('docs')) {
                    setCategories(data.docs);
                    setPageCount(data.totalPages);
                }
            });
        },
        [mountedRef, setCategories, setPageCount]
    );

    const createCategory = () => {
        history.push(routes.categories.create);
    };

    const updatePageIndex = (index: number) => {
        history.push(makeRoute.categoryIndex(index));
    };

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <DisplayCategory
                    viewMethod={viewMethod}
                    setViewMethod={setViewMethod}
                />
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.createButton}
                    startIcon={<AddIcon />}
                    onClick={createCategory}
                >
                    Create
                </Button>
            </div>
            <Grid container spacing={2}>
                {categories.map((category, index) => (
                    <Grid item xs={12} sm={6} md={4} xl={3} key={index}>
                        <CategoryCard
                            category={category}
                            refresh={() => forceUpdate((prev) => prev + 1)}
                        />
                    </Grid>
                ))}
            </Grid>

            <PaginationControlled
                pageCount={pageCount}
                pageIndex={pageIndex}
                updatePageIndex={updatePageIndex}
            />
        </div>
    );
};

interface Props extends RouteComponentProps {}

export default Categories;
