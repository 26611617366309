import { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: theme.palette.secondary.main,
            marginBottom: theme.spacing(2),
            flexGrow: 1,
            maxWidth: 'calc(100% - 68px)',
        },
        h2: {
            fontWeight: 'bold',
            marginBottom: theme.spacing(2),
            flexGrow: 1,
            lineHeight: '33px',
            maxWidth: 'calc(100% - 70px)',
            overflowWrap: 'break-word',
        },
    })
);

type Props = {
    text: string;
};

export const TitleBadgeLabel: FC<Props> = ({ text }) => {
    const classes = useStyles();
    return (
        <Typography variant={'h6'} className={classes.root}>
            {text || ''}
        </Typography>
    );
};

export const TitleLabel: FC<Props> = ({ text }) => {
    const classes = useStyles();
    return (
        <Typography variant={'h2'} className={classes.h2}>
            {text || ''}
        </Typography>
    );
};
