import React, { FC } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexWrap: 'wrap',
            width: '100%',
            margin: '0 auto',
            '& > *': {
                padding: theme.spacing(5),
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(2),
            },
        },
        button: {
            width: '100%',
            color: 'white',
        },
    })
);

interface Props {
    addNew: () => void;
    done: () => void;
}

const AddPoint: FC<Props> = ({ addNew, done }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Button
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={addNew}
                    >
                        New
                    </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Button
                        className={classes.button}
                        variant="contained"
                        color="secondary"
                        startIcon={<AssignmentTurnedInIcon />}
                        onClick={done}
                    >
                        Done
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
};

export default AddPoint;
