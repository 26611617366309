import React, { FC, useState, useEffect } from 'react';
import BackupIcon from '@material-ui/icons/Backup';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import CachedIcon from '@material-ui/icons/Cached';

import './style.scss';

interface Props {
    image?: any;
    updateImage: (img: File) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        font: {
            width: 100,
            height: '100%',
            color: theme.palette.primary.main,
            margin: '0 auto',
            display: 'block',
        },
        reload: {
            position: 'absolute',
            right: 15,
            top: 10,
            fontSize: 39,
            display: 'none',
        },
    })
);

const FileUploader: FC<Props> = ({ image, updateImage }) => {
    const classes = useStyles();
    const [previewImage, setPreviewImage] = useState<string | undefined>(image);
    const onImport = (evt: any) => {
        if (!evt.target.files || !evt.target.files.length) return;
        setPreviewImage(URL.createObjectURL(evt.target.files[0]));
        updateImage(evt.target.files[0]);
    };

    useEffect(() => {
        setPreviewImage(image);
    }, [image]);

    return (
        <div className="upload-container">
            <div className="upload-preview">
                {previewImage ? (
                    <>
                        <CachedIcon className={classes.reload} />
                        <img
                            src={previewImage}
                            alt="Preview"
                            className="preview-img"
                        />
                    </>
                ) : (
                    <BackupIcon className={classes.font} />
                )}
            </div>
            <input
                className="upload-action-input"
                type="file"
                onChange={onImport}
            />
        </div>
    );
};

export default FileUploader;
