import React, { FC, useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { RouteComponentProps } from 'react-router';
import Paper from '@material-ui/core/Paper';
import Alert from '@material-ui/lab/Alert';

import { makeRoute } from '../../configs/routes';
import { adminAPI } from '../../data/AdminAPI';
import { LOGIN_KEY, SESSION_KEY } from '../../data/Constants';

const useStyles = makeStyles((theme) => ({
    root: {},
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: '#154473',
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: '#154473',
        color: 'white',
    },
    container: {
        padding: theme.spacing(3),
        marginTop: theme.spacing(20),
    },
}));

interface Props extends RouteComponentProps {}

const Login: FC<Props> = ({ history }) => {
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rememberPassword, setRememberPassword] = useState(false);
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        const isRemembered = localStorage.getItem(LOGIN_KEY) === 'true';
        if (isRemembered) {
            history.push(makeRoute.checklistIndex(1));
        }
    }, []); // eslint-disable-line

    const postLogin = async () => {
        try {
            const ret = await adminAPI.loginRequest(email, password);
            if (ret) {
                //save login session
                localStorage.setItem(LOGIN_KEY, rememberPassword.toString());
                localStorage.setItem(SESSION_KEY, 'true');

                setShowError(false);
                history.push(makeRoute.checklistIndex(1));
            } else {
                setShowError(true);
            }
        } catch (e) {
            setShowError(true);
        }
    };

    const rememberPasswordSet = (evt: any) => {
        setRememberPassword(evt.target.checked);
    };

    return (
        <Container className={classes.root} component="main" maxWidth="sm">
            <Paper elevation={3} className={classes.container}>
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>

                    {showError && (
                        <Alert severity="error">
                            Email or password is incorrect!
                        </Alert>
                    )}

                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={(evt) => setEmail(evt.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(evt) => setPassword(evt.target.value)}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                value="remember"
                                color="primary"
                                checked={rememberPassword}
                                onChange={rememberPasswordSet}
                            />
                        }
                        label="Trust and remember this browser"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        className={classes.submit}
                        onClick={postLogin}
                    >
                        Sign In
                    </Button>
                </div>
            </Paper>
        </Container>
    );
};

export default Login;
