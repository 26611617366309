import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(3),
            flexGrow: 1,
            '& > * + *': {
                marginTop: theme.spacing(2),
            },
        },
        paginationRoot: {
            width: 'fit-content',
            margin: '0 auto',
            '& .Mui-selected': {
                backgroundColor: theme.palette.primary.main,
                color: 'white',
            },
            '& .Mui-selected:hover': {
                backgroundColor: theme.palette.primary.light,
                color: 'white',
            },
        },
    })
);

export default function PaginationControlled(props: Props) {
    const { pageCount, pageIndex } = props;
    const classes = useStyles();
    const handleChange = (_: any, value: number) => {
        props.updatePageIndex(value);
    };

    return (
        <div className={classes.root}>
            <Pagination
                classes={{ root: classes.paginationRoot }}
                count={pageCount}
                page={pageIndex}
                onChange={handleChange}
                disabled={pageCount <= 1}
            />
        </div>
    );
}

interface Props {
    pageCount: number;
    pageIndex: number;
    updatePageIndex: (pageIndex: number) => void;
}
