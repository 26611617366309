import React from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import { red } from '@material-ui/core/colors';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import { useHistory } from 'react-router';
import { useToasts } from 'react-toast-notifications';

import { Checklist } from '../../data/model/Checklist';
import { THUMBNAIL_BASE_URL } from '../../data/Constants';
import { adminAPI } from '../../data/AdminAPI';
import { routes } from '../../configs/routes';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
        margin: '0 auto',
        cursor: 'pointer',
    },
    flexRoot: {
        width: '100%',
        margin: '0 auto',
        cursor: 'pointer',
        display: 'flex',
        height: 150,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
        backgroundSize: 'contain',
    },
    flexMedia: {
        minWidth: 150,
        width: 150,
        height: 150,
        backgroundSize: 'contain',
    },
    expand: {
        marginLeft: 'auto',
    },
    avatar: {
        backgroundColor: red[500],
    },
    title: {
        fontSize: 20,
        height: 50,
        marginBottom: theme.spacing(1),
        overflow: 'hidden',
    },
    flexTitle: {
        fontSize: 20,
        overflow: 'hidden',
    },
    subheader: {
        textAlign: 'right',
    },
    flexSubheader: {
        textAlign: 'left',
    },
    flexRow: {
        width: '100%',
    },
}));

interface Props {
    viewMethod: string;
    data: Checklist;
    refresh: () => void;
}

export default function ChecklistCard(props: Props) {
    const { data, viewMethod } = props;
    const classes = useStyles();
    const history = useHistory();
    const { addToast } = useToasts();

    const viewChecklist = () => {
        history.push({
            pathname: routes.checklists.view,
            state: { data },
        });
    };

    return viewMethod === 'grid' ? (
        <Card className={classes.root} elevation={3}>
            <CardHeader
                title={data.title}
                subheader={moment(data.createdAt).format('DD MMM, YYYY')}
                classes={{ title: classes.title, subheader: classes.subheader }}
            />
            <CardMedia
                className={classes.media}
                image={
                    data.postFile
                        ? THUMBNAIL_BASE_URL + data.postFile
                        : '/logo512.png'
                }
                title=""
            />

            <CardActions disableSpacing>
                <IconButton aria-label="share" onClick={viewChecklist}>
                    <EditIcon />
                </IconButton>
                <IconButton
                    className={classes.expand}
                    onClick={async () => {
                        if (data.hasOwnProperty('_id')) {
                            const response = await adminAPI.removeChecklistRequest(
                                (data as any)['_id']
                            );
                            if (response.code === 200) {
                                addToast(response.message, {
                                    appearance: 'success',
                                });
                                props.refresh();
                            } else {
                                addToast(response.message, {
                                    appearance: 'error',
                                });
                            }
                        }
                    }}
                    aria-label="remove"
                >
                    <DeleteForeverIcon />
                </IconButton>
            </CardActions>
        </Card>
    ) : (
        <Card className={classes.flexRoot} elevation={3}>
            <CardMedia
                className={classes.flexMedia}
                image={
                    data.postFile
                        ? THUMBNAIL_BASE_URL + data.postFile
                        : '/logo512.png'
                }
                title=""
            />
            <div className={classes.flexRow}>
                <CardHeader
                    title={data.title}
                    subheader={moment(data.createdAt).format('DD MMM, YYYY')}
                    classes={{
                        title: classes.flexTitle,
                        subheader: classes.flexSubheader,
                    }}
                />
                <CardActions disableSpacing>
                    <IconButton aria-label="share" onClick={viewChecklist}>
                        <EditIcon />
                    </IconButton>
                    <IconButton
                        className={classes.expand}
                        onClick={async () => {
                            if (data.hasOwnProperty('_id')) {
                                const response = await adminAPI.removeChecklistRequest(
                                    (data as any)['_id']
                                );
                                if (response.code === 200) {
                                    addToast(response.message, {
                                        appearance: 'success',
                                    });
                                    props.refresh();
                                } else {
                                    addToast(response.message, {
                                        appearance: 'error',
                                    });
                                }
                            }
                        }}
                        aria-label="remove"
                    >
                        <DeleteForeverIcon />
                    </IconButton>
                </CardActions>
            </div>
        </Card>
    );
}
