import React, { FC, useState, useCallback } from 'react';
import { RouteComponentProps } from 'react-router';
import Grid from '@material-ui/core/Grid';
import { useToasts } from 'react-toast-notifications';
import arrayMove from 'array-move';

import { Checklist, ChecklistField } from '../../data/model/Checklist';
import ChecklistInputFields from '../../components/ChecklistInputFields';
import ChecklistMetaFields from '../../components/ChecklistMetaFields';
import ChecklistMetaViews from '../../components/ChecklistMetaViews';
import ChecklistInputViews from '../../components/ChecklistInputViews';
import AddPoint from '../../components/AddPoint';
import { adminAPI } from '../../data/AdminAPI';
import { validateSession } from '../../utils/helper';

const ChecklistsCreate: FC<Props> = (props) => {
    validateSession(props.history);
    const { addToast } = useToasts();

    const [newChecklist, setNewChecklist] = useState<Checklist>(
        new Checklist()
    );

    const [metaDataEditable, setMetaDataEditable] = useState<boolean>(true);
    const [checklistEditables, setChecklistEditables] = useState<boolean[]>(
        new Array(100).fill(true)
    );

    const newInputFieldsCompleted = (
        newField: ChecklistField,
        index: number,
        editable: boolean
    ) => {
        updateInputFieldEditables(checklistEditables, index, editable);

        const _newChecklist = { ...newChecklist };
        _newChecklist.fields[index] = newField;
        setNewChecklist(_newChecklist);
    };

    const updateInputFieldEditables = useCallback(
        (editables: boolean[], index: number, editable: boolean) => {
            const _editables = [...editables];
            _editables[index] = editable;
            setChecklistEditables(_editables);
        },
        [setChecklistEditables]
    );

    const addNewChecklistOption = () => {
        const _newChecklist = { ...newChecklist };
        _newChecklist.fields.push(new ChecklistField());
        setNewChecklist(_newChecklist);
    };

    const onComplete = async () => {
        try {
            const response = await adminAPI.createChecklistRequest(
                newChecklist
            );
            if (response.code === 200) {
                setNewChecklist(new Checklist());
                setMetaDataEditable(true);
                setChecklistEditables(new Array(100).fill(true));

                addToast(response.message, { appearance: 'success' });
            } else {
                addToast(response.message, { appearance: 'error' });
            }
        } catch (e) {
            console.log(
                '🚀🚀🚀 ~ file: create.tsx ~ line 71 ~ onComplete ~ error',
                e
            );
        }
    };

    const removeInputField = (fieldIndex: number) => {
        const _newChecklist = { ...newChecklist };
        if (fieldIndex === -1) {
            _newChecklist.title = '';
            _newChecklist.postUrl = '';
            _newChecklist.thumbnail = '';
        } else {
            updateInputFieldEditables(checklistEditables, fieldIndex, true);
            const _updatedFields = _newChecklist.fields.filter(
                (_, index) => index !== fieldIndex
            );
            _newChecklist.fields = _updatedFields;
            setNewChecklist(_newChecklist);
        }
    };

    const addNewInputField = (fieldIndex: number, field: ChecklistField) => {
        const _newChecklist = { ...newChecklist };
        _newChecklist.fields.splice(fieldIndex, 0, field);
        setNewChecklist(_newChecklist);
        updateInputFieldEditables(checklistEditables, fieldIndex, true);
    };

    const changeFieldPosition = (orgIndex: number, newIndex: number) => {
        if (
            orgIndex < 0 ||
            newIndex < 0 ||
            orgIndex >= newChecklist.fields.length ||
            newIndex >= newChecklist.fields.length
        ) {
            return;
        }
        const _newChecklist = { ...newChecklist };
        _newChecklist.fields[orgIndex].index = newIndex;
        _newChecklist.fields[newIndex].index = orgIndex;
        _newChecklist.fields = arrayMove(
            _newChecklist.fields,
            orgIndex,
            newIndex
        );
        setNewChecklist(_newChecklist);
    };

    const cancelStepInput = (fieldIndex: number) => {
        const _newChecklist = { ...newChecklist };
        _newChecklist.fields.splice(fieldIndex, 1);
        updateInputFieldEditables(checklistEditables, fieldIndex, false);
        setNewChecklist(_newChecklist);
    };

    return (
        <Grid container justify="center">
            <Grid item xs={12} sm={6} md={5} xl={4}>
                {metaDataEditable ? (
                    <ChecklistMetaFields
                        {...props}
                        data={newChecklist}
                        updateChecklist={(_newChecklist, editable) => {
                            setNewChecklist(_newChecklist);
                            setMetaDataEditable(editable);
                        }}
                    />
                ) : (
                    <ChecklistMetaViews
                        data={newChecklist}
                        editMetadataField={() => setMetaDataEditable(true)}
                        removeChecklistField={() => removeInputField(-1)}
                    />
                )}

                {newChecklist.fields.map((field, index) =>
                    checklistEditables[index] ? (
                        <ChecklistInputFields
                            {...props}
                            data={field}
                            parentTitle={newChecklist.title}
                            fieldIndex={index}
                            onFinish={(newField, editable) => {
                                newInputFieldsCompleted(
                                    newField,
                                    index,
                                    editable
                                );
                            }}
                            onCancel={cancelStepInput}
                            key={index}
                        />
                    ) : (
                        <ChecklistInputViews
                            {...props}
                            data={field}
                            key={index}
                            fieldIndex={index}
                            editChecklistField={(
                                index: number,
                                editable: boolean
                            ) =>
                                updateInputFieldEditables(
                                    checklistEditables,
                                    index,
                                    editable
                                )
                            }
                            removeChecklistField={removeInputField}
                            addChecklistField={addNewInputField}
                            changeFieldPosition={changeFieldPosition}
                        />
                    )
                )}

                {!metaDataEditable &&
                    !checklistEditables[newChecklist.fields.length - 1] && (
                        <AddPoint
                            addNew={addNewChecklistOption}
                            done={onComplete}
                        />
                    )}
            </Grid>
        </Grid>
    );
};

interface Props extends RouteComponentProps {}

export default ChecklistsCreate;
