import React, { FC, useState, useEffect } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Radio from '@material-ui/core/Radio';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import Alert from '@material-ui/lab/Alert';
import { RouteComponentProps } from 'react-router';
import IconButton from '@material-ui/core/IconButton';

import CssTextField from '../../components/TextField';
import { TitleBadgeLabel } from '../../components/Typography';
import { ChecklistField } from '../../data/model/Checklist';
import {
    Answer,
    AnswerType,
    OptionAnswer,
} from '../../data/model/Answer/Answer';
import IndexLabel from '../IndexLabel';
import FileUploader from '../FileUploader';
import { generateImageUrl } from '../../utils/helper';
import TagsInput from '../TagsInput';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexWrap: 'wrap',
            width: '100%',
            margin: '0 auto',
            '& > *': {
                padding: theme.spacing(5),
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(2),
            },
        },
        paperRoot: {
            borderLeft: `3px solid ${theme.palette.primary.main}`,
        },
        button: {
            width: '100%',
            color: 'white',
        },
        formControl: {
            width: '100%',
            marginBottom: theme.spacing(2),
            '& .MuiInputBase-root': {
                height: 48,
            },
        },
        radioText: {
            flexGrow: 1,
        },
        optionContainer: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(3),
        },
        gridContaienr: {
            marginTop: theme.spacing(2),
        },
    })
);

interface Props extends RouteComponentProps {
    data: ChecklistField;
    parentTitle: string;
    fieldIndex: number;
    onFinish: (field: ChecklistField, editable: boolean) => void;
    onCancel: (fieldIndex: number) => void;
}

const ChecklistInputFields: FC<Props> = (props) => {
    const classes = useStyles();
    const { fieldIndex, data, onFinish, onCancel } = props;

    const [title, setTitle] = useState(data.title);
    const [postUrl, setPostUrl] = useState(data.postUrl);
    const [description, setDescription] = useState(data.description);
    const [answerType, setAnswerType] = useState(data.answer.type);
    const [showOptionContainer, setShowOptionContainer] = useState(
        data.answer.type === AnswerType.OPTION
    );
    const [optionAnswers, setOptionAnswers] = useState<OptionAnswer[]>(
        data.answer.descriptions
    );
    const [showError, setShowError] = useState(false);
    const [tags, setTags] = useState<string[]>(data.tags);

    useEffect(() => {
        setTitle(data.title);
        setPostUrl(data.postUrl);
        setDescription(data.description);
        setAnswerType(data.answer.type);
        setShowOptionContainer(data.answer.type === AnswerType.OPTION);
        setOptionAnswers(data.answer.descriptions);
        setTags(data.tags);
    }, [data]);

    const setChecklistMetaData = () => {
        if (title.trim() === '') {
            setShowError(true);
            return;
        }
        const newField = { ...data };
        if (!newField.index) {
            newField.index = fieldIndex + 1;
        }
        newField.title = title;
        newField.postUrl = postUrl;
        newField.description = description;

        const answer = new Answer();
        answer.type = answerType;
        if (answerType === AnswerType.OPTION) {
            answer.descriptions = optionAnswers;
        }

        newField.answer = answer;
        newField.tags = tags;

        onFinish(newField, false);
    };

    const updateImage = (file: File) => {
        const _updatedField = { ...data };
        _updatedField.postFile = file;

        onFinish(_updatedField, true);
    };

    const handleAnswerType = (event: any) => {
        setAnswerType(event.target.value);
        setShowOptionContainer(event.target.value === AnswerType.OPTION);
    };

    const addNewOptionAnswer = () => {
        setOptionAnswers([...optionAnswers, new OptionAnswer()]);
    };

    const removeOptionAnswer = (index: number) => {
        const newOptionAnswers = optionAnswers.filter(
            (_, pos) => pos !== index
        );

        setOptionAnswers(newOptionAnswers);
    };

    const optionAnswerChanged = (evt: any, index: number) => {
        const _optionAnswers = [...optionAnswers];
        _optionAnswers[index].answer = evt.target.value;

        setOptionAnswers(_optionAnswers);
    };

    const optionAnswerRadioChanged = (evt: any, index: number) => {
        const _optionAnswers = [...optionAnswers];
        _optionAnswers[index].checked = evt.target.checked;
        setOptionAnswers(_optionAnswers);
    };

    return (
        <div className="container">
            <div className={classes.root}>
                <Paper elevation={3} className={classes.paperRoot}>
                    <IndexLabel text={fieldIndex + 1} />
                    {showError && (
                        <Alert severity="error">Title can not be empty!</Alert>
                    )}

                    <CssTextField
                        label="Title"
                        value={title}
                        onChange={(evt) => setTitle(evt.target.value)}
                    />
                    <CssTextField
                        label="Image or Video URL"
                        value={postUrl}
                        onChange={(evt) => setPostUrl(evt.target.value)}
                    />
                    <TitleBadgeLabel text="UPLOAD FILE" />
                    <FileUploader
                        image={generateImageUrl(data.postFile)}
                        updateImage={updateImage}
                    />
                    <CssTextField
                        multiline={true}
                        label="Description"
                        value={description}
                        onChange={(evt) => setDescription(evt.target.value)}
                    />

                    <FormControl className={classes.formControl}>
                        <InputLabel
                            id="demo-simple-select-outlined-label"
                            color={'secondary'}
                        >
                            Answer Type
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={answerType}
                            color={'secondary'}
                            onChange={handleAnswerType}
                            label="Categories"
                        >
                            <MenuItem value={0}>CheckBox</MenuItem>
                            <MenuItem value={1}>Option</MenuItem>
                            <MenuItem value={2}>Yes/No</MenuItem>
                        </Select>
                    </FormControl>

                    <div
                        className={classes.optionContainer}
                        hidden={!showOptionContainer}
                    >
                        <Button
                            className={classes.button}
                            variant="contained"
                            color="secondary"
                            startIcon={<AddCircleOutlineIcon />}
                            onClick={addNewOptionAnswer}
                        >
                            Add New Option
                        </Button>
                        {optionAnswers.map((_, index) => (
                            <Grid
                                className={classes.gridContaienr}
                                container
                                spacing={2}
                                key={index}
                            >
                                <Grid item>
                                    <Radio
                                        checked={optionAnswers[index].checked}
                                        onChange={(evt) =>
                                            optionAnswerRadioChanged(evt, index)
                                        }
                                    />
                                </Grid>
                                <Grid item className={classes.radioText}>
                                    <CssTextField
                                        value={optionAnswers[index].answer}
                                        onChange={(evt) =>
                                            optionAnswerChanged(evt, index)
                                        }
                                    />
                                </Grid>
                                <Grid item>
                                    <IconButton
                                        aria-label="edit"
                                        onClick={() =>
                                            removeOptionAnswer(index)
                                        }
                                    >
                                        <CancelIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        ))}
                    </div>

                    <TagsInput
                        tags={tags}
                        onSubmit={(_tags) => setTags(_tags)}
                        viewOnly={false}
                    />

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <Button
                                className={classes.button}
                                variant="contained"
                                color="primary"
                                onClick={setChecklistMetaData}
                            >
                                Confirm
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button
                                className={classes.button}
                                variant="contained"
                                color="secondary"
                                onClick={() => onCancel(fieldIndex)}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        </div>
    );
};

export default ChecklistInputFields;
