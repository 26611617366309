import React, { FC } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import CardMedia from '@material-ui/core/CardMedia';

import { Checklist } from '../../data/model/Checklist';
import { TitleLabel } from '../Typography';
import { generateImageUrl } from '../../utils/helper';
import CategorySelect from '../CategorySelect';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexWrap: 'wrap',
            width: '100%',
            margin: '0 auto',
            '& > *': {
                padding: theme.spacing(5),
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(2),
            },
        },
        button: {
            width: '100%',
        },
        formControl: {
            width: '100%',
            marginBottom: theme.spacing(2),
            '& .MuiInputBase-root': {
                height: 48,
            },
        },
        paperRoot: {
            borderLeft: `3px solid ${theme.palette.primary.main}`,
        },
        toolbar: {
            textAlign: 'right',
        },
        media: {
            height: 0,
            paddingTop: '56.25%', // 16:9
            backgroundSize: 'contain',
        },
        link: {
            lineBreak: 'anywhere',
        },
        select: {
            marginTop: theme.spacing(3),
        },
    })
);

const ChecklistMetaViews: FC<Props> = ({ data, editMetadataField }) => {
    const classes = useStyles();

    const photoUrl = generateImageUrl(data.postFile);
    return (
        <div className={classes.root}>
            <Paper elevation={3} className={classes.paperRoot}>
                <Grid container spacing={2}>
                    <Grid item xs={12} className={classes.toolbar}>
                        <IconButton
                            aria-label="edit"
                            onClick={() => editMetadataField()}
                        >
                            <EditIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <TitleLabel text={data.title} />
                <Link
                    variant={'h4'}
                    color={'secondary'}
                    underline="always"
                    className={classes.link}
                >
                    {data.postUrl}
                </Link>
                {photoUrl && (
                    <CardMedia
                        className={classes.media}
                        image={photoUrl}
                        title="Meta Photo"
                    />
                )}
                <div className={classes.select}>
                    <CategorySelect
                        category={data.category}
                        updateCategory={() => {}}
                        disabled={true}
                    />
                </div>
            </Paper>
        </div>
    );
};

interface Props {
    data: Checklist;
    editMetadataField: () => void;
    removeChecklistField: () => void;
}

export default ChecklistMetaViews;
