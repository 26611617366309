import React, { FC } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import { useToasts } from 'react-toast-notifications';

import { Category } from '../../data/model/Category';
import { routes } from '../../configs/routes';
import { adminAPI } from '../../data/AdminAPI';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
    },
    expand: {
        marginLeft: 'auto',
    },
    title: {
        fontSize: 22,
        fontWeight: 600,
    },
}));

type Props = {
    category: Category;
    refresh: () => void;
};

const CategoryCard: FC<Props> = ({ category, refresh }) => {
    const classes = useStyles();
    const history = useHistory();
    const { addToast } = useToasts();

    const editCategory = () => {
        history.push({
            pathname: routes.categories.edit,
            state: { category },
        });
    };

    const removeCategory = async () => {
        const response = await adminAPI.removeCategoryRequest(
            (category as any)._id
        );
        if (response.code === 200) {
            addToast(response.message, {
                appearance: 'success',
            });
            refresh();
        } else {
            addToast(response.message, {
                appearance: 'error',
            });
        }
    };

    return (
        <Card elevation={3}>
            <CardHeader
                title={category.title}
                classes={{ title: classes.title }}
            />
            <CardActions disableSpacing>
                <IconButton aria-label="share" onClick={editCategory}>
                    <EditIcon />
                </IconButton>
                <IconButton
                    className={classes.expand}
                    onClick={removeCategory}
                    aria-label="remove"
                >
                    <DeleteForeverIcon />
                </IconButton>
            </CardActions>
        </Card>
    );
};

export default CategoryCard;
