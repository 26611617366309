import React, { FC, useState, useEffect } from 'react';
import Modal from 'react-modal';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

import { ChecklistField } from '../../data/model/Checklist';
import { adminAPI } from '../../data/AdminAPI';

Modal.setAppElement('#root');

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        overflow: 'inherit',
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 9,
    },
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            width: '100%',
            color: 'white',
        },
        buttons: {
            marginTop: theme.spacing(1),
        },
    })
);

const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: ChecklistField) =>
        option.tags.toString() + option.title + option.description,
});

interface Props {
    open: boolean;
    setOpen: (open: boolean) => void;
    onFinish: (field: ChecklistField) => void;
}

const TagSearchModal: FC<Props> = ({ open, setOpen, onFinish }) => {
    const classes = useStyles();

    const [fields, setFields] = useState<ChecklistField[]>([]);
    const [loading, setLoading] = useState(false);
    const [selectOpen, setSelectOpen] = useState(false);
    const [selectedField, setSelectedField] = useState<ChecklistField>(
        new ChecklistField()
    );

    useEffect(() => {
        setLoading(true);
        (async () => {
            const response = await adminAPI.getAllFieldsRequest();
            setFields(response);
            setLoading(false);
        })();
    }, []);

    const selectTagField = (will: boolean) => {
        if (will) {
            // when yes is clicked
            onFinish(selectedField);
        } else {
            onFinish(new ChecklistField());
        }
        setOpen(false);
        setSelectedField(new ChecklistField());
    };

    return (
        <Modal
            isOpen={open}
            onRequestClose={() => setOpen(false)}
            style={customStyles}
        >
            <h3>Would you like to input new step from existing steps?</h3>
            <Autocomplete
                id="asynchronous-fields-input"
                style={{ width: '100%' }}
                open={selectOpen}
                onOpen={() => setSelectOpen(true)}
                onClose={() => setSelectOpen(false)}
                getOptionSelected={(option, value) =>
                    option.tags === value.tags
                }
                getOptionLabel={(option) => option.title}
                options={fields}
                loading={loading}
                filterOptions={filterOptions}
                onChange={(evt, value) =>
                    setSelectedField(value as ChecklistField)
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Input Keyword"
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? (
                                        <CircularProgress
                                            color="inherit"
                                            size={20}
                                        />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />
            <Grid container spacing={3} className={classes.buttons}>
                <Grid item xs={12} sm={6}>
                    <Button
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        onClick={() => selectTagField(true)}
                    >
                        Yes
                    </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Button
                        className={classes.button}
                        variant="contained"
                        color="secondary"
                        onClick={() => selectTagField(false)}
                    >
                        No
                    </Button>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default TagSearchModal;
