import React, { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        indexLabel: {
            fontWeight: 'bold',
            color: 'gray',
            textAlign: 'center',
            marginRight: 10,
        },
    })
);

interface Props {
    text: any;
}

const IndexLabel: FC<Props> = ({ text }) => {
    const classes = useStyles();
    return (
        <Typography variant={'h1'} className={classes.indexLabel}>
            {text}
        </Typography>
    );
};

export default IndexLabel;
