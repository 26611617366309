import React, { useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';

import CssTextField from '../TextField';
import { TitleBadgeLabel } from '../Typography';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            listStyle: 'none',
            padding: theme.spacing(0.5),
            margin: 0,
            width: '100%',
            marginBottom: 30,
        },
        chip: {
            margin: theme.spacing(0.5),
            backgroundColor: theme.palette.primary.main,
            color: 'white',
        },
        title: {
            fontSize: 12,
        },
        deleteIcon: {
            color: 'white',
        },
    })
);

interface Props {
    tags: string[];
    onSubmit?: (tags: string[]) => void;
    viewOnly: boolean;
}

export default function TagsInput(props: Props) {
    const classes = useStyles();
    const [chipData, setChipData] = React.useState<string[]>(props.tags);
    const [tag, setTag] = useState('');

    useEffect(() => {
        setChipData(props.tags);
    }, [props.tags]);

    const handleDelete = (chipToDelete: string) => () => {
        setChipData((chips) => chips.filter((chip) => chip !== chipToDelete));
    };

    const onTagInput = (e: any) => {
        if (e.keyCode === 13) {
            const exists = chipData.filter((chip) => chip === tag);
            if (exists.length > 0) return;

            props.onSubmit!([...chipData, tag]);

            setChipData((chips) => [...chips, tag]);
            setTag('');
        }
    };

    return (
        <>
            <div style={{ marginTop: 10 }} />
            <TitleBadgeLabel text="Tags" />
            <Paper component="ul" className={classes.root}>
                {chipData.map((data) => {
                    return (
                        <li key={data}>
                            <Chip
                                label={data}
                                onDelete={handleDelete(data)}
                                classes={{
                                    root: classes.chip,
                                    deleteIcon: classes.deleteIcon,
                                }}
                                clickable={false}
                                disabled={props.viewOnly}
                            />
                        </li>
                    );
                })}
                {!props.viewOnly && (
                    <CssTextField
                        label=""
                        value={tag}
                        onChange={(evt) => {
                            setTag(evt.target.value);
                        }}
                        onKeyDown={onTagInput}
                    />
                )}
            </Paper>
        </>
    );
}
