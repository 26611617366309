import React, { FC, useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import { RouteComponentProps } from 'react-router';

import CssTextField from '../../components/TextField';
import { TitleBadgeLabel } from '../../components/Typography';
import { Checklist } from '../../data/model/Checklist';
import CategorySelect from '../CategorySelect';
import FileUploader from '../FileUploader';
import { generateImageUrl } from '../../utils/helper';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexWrap: 'wrap',
            width: '100%',
            margin: '0 auto',
            '& > *': {
                padding: theme.spacing(5),
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(2),
            },
        },
        button: {
            width: '100%',
            color: 'white',
        },
        actionBox: {
            marginTop: theme.spacing(1),
        },
    })
);

const ChecklistMetaFields: FC<Props> = (props) => {
    const { data, updateChecklist } = props;
    const classes = useStyles();

    const [title, setTitle] = useState(data.title);
    const [postUrl, setPostUrl] = useState(data.postUrl);
    const [showError, setShowError] = useState(false);
    const [category, setCategory] = useState(data.category || 'All');

    const setChecklistMetaData = () => {
        if (title.trim() === '') {
            setShowError(true);
            return;
        }
        setShowError(false);
        const _newChecklist = { ...data };
        _newChecklist.title = title;
        _newChecklist.postUrl = postUrl;
        _newChecklist.thumbnail = '';
        _newChecklist.category = category;

        updateChecklist(_newChecklist, false);
    };

    const updateImage = (file: File) => {
        const _newChecklist = { ...data };
        _newChecklist.postFile = file;
        updateChecklist(_newChecklist, true);
    };

    return (
        <div className={classes.root}>
            <Paper elevation={3}>
                {showError && (
                    <Alert severity="error">Title can not be empty!</Alert>
                )}

                <CssTextField
                    label="Title"
                    value={title}
                    onChange={(evt) => setTitle(evt.target.value)}
                />
                <CssTextField
                    label="Image or Video URL"
                    value={postUrl}
                    onChange={(evt) => setPostUrl(evt.target.value)}
                />
                <TitleBadgeLabel text="UPLOAD FILE" />
                <FileUploader
                    image={generateImageUrl(data.postFile)}
                    updateImage={updateImage}
                />
                <CategorySelect
                    category={category}
                    updateCategory={setCategory}
                />

                <Grid container spacing={10} className={classes.actionBox}>
                    <Grid item xs={12} sm={6}>
                        <Button
                            className={classes.button}
                            variant="contained"
                            color="primary"
                            onClick={setChecklistMetaData}
                        >
                            Confirm
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button
                            className={classes.button}
                            variant="contained"
                            color="secondary"
                            onClick={() => props.history.go(-1)}
                        >
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
};

interface Props extends RouteComponentProps {
    data: Checklist;
    updateChecklist: (_new: Checklist, editable: boolean) => void;
}

export default ChecklistMetaFields;
